<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Pants that are worn into the lab should:</p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-n6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uci51LCPrelab1Q3',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {text: 'be loose or baggy.', value: 'loose'},
        {text: 'be made of synthetic materials like nylon, polyester, etc.', value: 'synthetic'},
        {text: 'cover your whole leg.', value: 'coverWholeLeg'},
        {text: 'be dark in color.', value: 'dark'},
      ],
    };
  },
};
</script>
